import React, { useState, createContext, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const verifyAccessToken = () => {
    const accessToken =
      window.sessionStorage.getItem('accessToken') || window.localStorage.getItem('accessToken')
    if (!accessToken) return false

    const token = jwtDecode(accessToken)
    const currentTime = Math.floor(new Date().getTime() / 1000)
    return token.exp > currentTime
  }

  // STATE
  const [isAuthenticated, setIsAuthenticated] = useState(verifyAccessToken)

  const [sessionType, _setSessionType] = useState(
    window.sessionStorage.getItem('sessionType') || window.localStorage.getItem('sessionType')
  )
  const setSessionType = (st) => {
    if (st) {
      if (st !== 'LOGIN') window.sessionStorage.setItem('sessionType', st)
      else window.localStorage.setItem('sessionType', st)
    }
    _setSessionType(st)
  }

  const [lmsName, _setLmsName] = useState(window.sessionStorage.getItem('lmsName'))
  const setLmsName = (name) => {
    window.sessionStorage.setItem('lmsName', name)
    _setLmsName(name)
  }

  const hasValidRefreshToken = () => {
    const refreshToken =
      window.sessionStorage.getItem('refreshToken') || window.localStorage.getItem('refreshToken')

    return refreshToken && jwtDecode(refreshToken).exp > Math.floor(new Date().getTime() / 1000)
  }
  const getDevice = (st = 'LOGIN') => {
    let deviceId =
      window.sessionStorage.getItem('deviceId') || window.localStorage.getItem('deviceId')
    if (!deviceId) {
      deviceId = uuidv4()
      if (st !== 'LOGIN') window.sessionStorage.setItem('deviceId', deviceId)
      else window.localStorage.setItem('deviceId', deviceId)
    } else if (st !== 'ANONYMOUS') {
      // After getting the getCurrentSession, we know the sessionType and
      // we should remove the deviceId from sessionStorage
      window.localStorage.setItem('deviceId', deviceId)
      window.sessionStorage.removeItem('deviceId')
    }
    return {
      id: deviceId,
      userAgent: window.navigator.userAgent,
    }
  }

  const setTokens = (accessToken, refreshToken, st = 'LOGIN') => {
    setSessionType(st)
    if (st !== 'LOGIN') {
      window.sessionStorage.setItem('accessToken', accessToken)
      window.sessionStorage.setItem('refreshToken', refreshToken)
    } else {
      window.localStorage.setItem('accessToken', accessToken)
      window.localStorage.setItem('refreshToken', refreshToken)
    }
  }
  const removeTokens = (st = 'LOGIN', clearSessionType = true) => {
    if (st !== 'LOGIN') {
      window.sessionStorage.removeItem('accessToken')
      window.sessionStorage.removeItem('refreshToken')
      window.sessionStorage.removeItem('sessionType')
    } else {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
      window.localStorage.removeItem('sessionType')
    }
    if (clearSessionType) setSessionType(null)
  }
  const store = useMemo(
    () => ({
      isAuthenticated: [isAuthenticated, setIsAuthenticated],
      verifyAccessToken,
      hasValidRefreshToken,
      getDevice,
      setTokens,
      removeTokens,
      sessionType: [sessionType, setSessionType],
      lmsName: [lmsName, setLmsName],
    }),
    [isAuthenticated, sessionType]
  )

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
